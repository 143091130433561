import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import axios from 'axios';
import filter from 'lodash/filter';
import type { LabJob } from '../types/labJob';
import { ChemicalResult, ObservationResult } from '../types/water-test';
import { SPIN_READER } from 'src/constants/water-test';
import { labJobApi } from 'src/api/labjob';
import { environmentConfig } from 'src/config';
import { format } from 'date-fns';
import { analytics } from 'src/lib/firebase';
import { logEvent } from 'firebase/analytics';

interface GenerateReportBody {
  id: number;
  nextTestDate: Date | null;
  chemicalResults: ChemicalResult[];
  observationResults: ObservationResult[];
  reportData: any;
}

export interface ReportConfig {
  layout: string;
  showCampaign: boolean;
  showLogo: boolean;
}

interface WaterTestState {
  isLoading: boolean;
  labJob?: LabJob;
  isPreviewPdfOpen: boolean;
  reportConfig: ReportConfig;
  generatingReport: 'idle' | 'pending' | 'succeeded' | 'failed';
  errorReport: null | string;
  connectedDevice: string;
}

const initialState: WaterTestState = {
  isLoading: false,
  labJob: null,
  reportConfig: {
    layout: environmentConfig.defaultReport,
    showLogo: true,
    showCampaign: false,
  },
  isPreviewPdfOpen: false,
  generatingReport: 'idle',
  errorReport: null,
  connectedDevice: SPIN_READER,
};

// TODO: move to thunk
export const generateWaterTestReport = createAsyncThunk<void, GenerateReportBody>(
  'water-test-report/generate',
  async (
    { id, nextTestDate, chemicalResults, observationResults, reportData },
    { getState, rejectWithValue }
  ) => {
    // @ts-ignore
    const { organisation } = getState().account;
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const fd = new FormData();

    try {
      const extension = reportData.type.split('/')[1];
      fd.append('file', reportData, `filename.${extension}`);
      await axios.post(`v2/organisations/${organisation.id}/water-test/${id}/report`, fd, config);
      const activeChemicalResults = filter(
        chemicalResults,
        (chemicalResult) => chemicalResult.value !== ''
      );
      const activeObservationResults = filter(
        observationResults,
        (observationResult) => observationResult.value
      );
      const resultsData = {
        next_test_date: nextTestDate ? format(nextTestDate, 'yyyy-MM-dd') : null,
        chemical_results: activeChemicalResults.map((chemicalResult: ChemicalResult) => ({
          value: chemicalResult.value,
          name: chemicalResult.name,
          action: chemicalResult.action || '',
          status: chemicalResult.status,
          min_value: chemicalResult.min_value,
          max_value: chemicalResult.max_value,
          target_value: chemicalResult.target_value,
          description: chemicalResult.description || '',
          short_description: chemicalResult.short_description || '',
          show_on_report: chemicalResult.show_on_report,
          chemical_test_id: chemicalResult.chemical_test_id,
          chemical_group_id: chemicalResult.chemical_group_id || null,
        })),
        observation_results: activeObservationResults.map(
          (observationResult: ObservationResult) => ({
            value: observationResult.value,
            name: observationResult.name,
            action: observationResult.action || '',
            description: observationResult.description || '',
            short_description: observationResult.short_description || '',
            show_on_report: observationResult.show_on_report,
            observation_test_id: observationResult.observation_test_id,
            observation_group_id: observationResult.observation_group_id || null,
          })
        ),
      };

      logEvent(analytics(), 'generate_report', {
        type: 'water_test_report',
        test_results: activeChemicalResults.length,
        observation_results: activeObservationResults.length,
      });

      return axios.post(
        `v2/organisations/${organisation.id}/water-test/${id}/results`,
        resultsData
      );
    } catch (e) {
      return rejectWithValue({
        error: JSON.stringify(e.response?.data),
      });
    }
  }
);

const slice = createSlice({
  name: 'waterTest',
  initialState,
  reducers: {
    reset(state: WaterTestState): void {
      state = { ...initialState, connectedDevice: state.connectedDevice };
    },
    openPreviewPdf(state: WaterTestState): void {
      state.isPreviewPdfOpen = true;
    },
    closePreviewPdf(state: WaterTestState): void {
      state.isPreviewPdfOpen = false;
    },
    changeLayout(state: WaterTestState, action: PayloadAction<{ layout: string }>): void {
      const { layout } = action.payload;
      state.reportConfig = {
        ...state.reportConfig,
        layout,
      };
    },
    changeDevice(state: WaterTestState, action: PayloadAction<{ device: string }>): void {
      const { device } = action.payload;
      state.connectedDevice = device;
    },
    applyReportConfig(
      state: WaterTestState,
      action: PayloadAction<{ layout: string; showLogo: boolean; showCampaign: boolean }>
    ): void {
      const { layout, showLogo, showCampaign } = action.payload;
      state.reportConfig = {
        layout,
        showLogo,
        showCampaign,
      };
    },
    toggleLogoSetting(state: WaterTestState): void {
      state.reportConfig = {
        ...state.reportConfig,
        showLogo: !state.reportConfig.showLogo,
      };
    },
    toggleCampaignSetting(state: WaterTestState): void {
      state.reportConfig = {
        ...state.reportConfig,
        showCampaign: !state.reportConfig.showCampaign,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateWaterTestReport.pending, (state: WaterTestState) => {
      state.generatingReport = 'pending';
      state.errorReport = null;
    });
    builder.addCase(generateWaterTestReport.fulfilled, (state: WaterTestState, action) => {
      // @ts-ignore
      const { data } = action.payload;
      state.labJob = data;
      state.generatingReport = 'succeeded';
      state.errorReport = null;
    });
    builder
      .addCase(generateWaterTestReport.rejected, (state: WaterTestState, action) => {
        state.generatingReport = 'failed';
        state.errorReport = null;
      })
      .addMatcher(labJobApi.endpoints.getLabJob.matchPending, (state, action) => {
        state.isLoading = true;
      })
      .addMatcher(labJobApi.endpoints.getLabJob.matchFulfilled, (state, action) => {
        state.labJob = action.payload;
      })
      .addMatcher(labJobApi.endpoints.getLabJob.matchRejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const { reducer } = slice;

export const reset =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.reset());
  };

export const openPreviewPdf =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.openPreviewPdf());
  };

export const closePreviewPdf =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.closePreviewPdf());
  };

export const changeLayout = (layout: string) => (dispatch) => {
  dispatch(slice.actions.changeLayout({ layout }));
};

export const toggleLogoSetting =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.toggleLogoSetting());
  };

export const toggleCampaignSetting =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.toggleCampaignSetting());
  };

export const applyReportConfig =
  (layout: string, showLogo: boolean, showCampaign: boolean) => (dispatch) => {
    dispatch(slice.actions.applyReportConfig({ layout, showLogo, showCampaign }));
  };

export const changeDevice = (device: string) => (dispatch) => {
  dispatch(slice.actions.changeDevice({ device }));
};

export default slice;
